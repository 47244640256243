import { getProperty } from './helperFn';

export const getOrderJourneyTag = (salesStatus) => {
  switch (salesStatus) {
    case 'ORDER':
      return '?s=1';
    case 'PRE_ORDER':
      return '?s=2';
    case 'REGISTER_INTEREST':
      return '?s=3';
    case null:
      return '?s=3';
    case 'REGISTER_INTEREST_PLUS':
      return '?s=4';
    default:
      return '?s=3';
  }
};

export const createLeadContactBillingAddressPayload = (RegistrationData, FormData) => {
  const utmData = JSON.parse(localStorage.getItem('utmData'));

  try {
    const payload = [
      {
        entity: 'CrmModule:Lead',
        title: getProperty(RegistrationData.searchPremiseResults, 'title'),
        schemaId: 'dc2440a6-651a-4013-aaaa-c54535873075',
        properties: {
          Type: RegistrationData.customerType ? RegistrationData.customerType.toUpperCase() : 'RESIDENTIAL',
          Name: getProperty(RegistrationData.searchPremiseResults.properties, 'FullAddress'),
          Source: RegistrationData.Source,
          EmailAddress: FormData.email,
          UtmCampaign: utmData ? utmData.utmCampaign : undefined,
          UtmMedium: utmData ? utmData.utmMedium : undefined,
          UtmSource: utmData ? utmData.utmSource : undefined,
          UtmContent: utmData ? utmData.utmContent : undefined,
          UtmTerm: utmData ? utmData.utmTerm : undefined,
          CompanyName: FormData.companyName,
          LandingPage: sessionStorage.getItem('slug'),
          CompanyPosition: FormData.companyPosition,
          CurrentSpeed: FormData.currentSpeed,
          DateOfConsent: RegistrationData.dateOfConsent,
          UTRNumber: FormData.utrNumber,
          VAT: FormData.vat ? FormData.vat : null,
          NumberOfMonthsLeft: FormData.numberOfMonthsLeft
        },
        associations: [
          {
            entity: 'ProductModule:Offer',
            recordId: RegistrationData.offerId,
          },
        ],
      },
      {
        entity: 'CrmModule:Contact',
        title: `${FormData.firstName} ${FormData.lastName}`,
        schemaId: 'e38bfb72-a9ee-4cfd-8dba-138bc26e8a5a',
        properties: {
          CompanyName: FormData.companyName ? FormData.companyName : null,
          CompanyPosition: FormData.companyPosition ? FormData.companyPosition : null,
          EmailAddress: FormData.email,
          Title: FormData.title?.value,
          LastName: FormData.lastName,
          FirstName: FormData.firstName,
          Phone: FormData.phoneNumber,
          PreviousProvider: RegistrationData?.currentProvider ? (RegistrationData?.currentProvider === 'Other' ? RegistrationData?.currentProviderOther : RegistrationData?.currentProvider) : null,
          CurrentContractType: RegistrationData?.currentContractType ? RegistrationData?.currentContractType : (RegistrationData?.personalData?.currentProvider ? 'IN_CONTRACT' : 'OUT_OF_CONTRACT'),
          HearAboutUs: FormData.hearAboutUsValue,
          HearAboutUsOther: FormData.hearAboutUsOther,
          TwilioVerifiedPhone: true,
          TermsAndConditionsConsent: FormData?.termsAndConditionsConsent || false,
          DirectDebitConsent: FormData?.directDebitConsent || false,
          HomeownerStatus: FormData?.homeownerStatus || null,
          MarketingConsent: FormData?.marketingConsent || false
        },
      },
      {
        entity: 'CrmModule:Address',
        title: getProperty(RegistrationData.searchPremiseResults.properties, 'FullAddress'),
        schemaId: '89dceeaf-3990-48e7-b914-247ac91b6e3c',
        properties: {
          FullAddress: getProperty(RegistrationData.searchPremiseResults.properties, 'FullAddress'),
          City: getProperty(RegistrationData.searchPremiseResults.properties, 'PostTown'),
          AddressLine1: getProperty(RegistrationData.searchPremiseResults.properties, 'AddressLine1')
            ? getProperty(RegistrationData.searchPremiseResults.properties, 'AddressLine1')
            : null,
          AddressLine2: getProperty(RegistrationData.searchPremiseResults.properties, 'AddressLine2')
            ? getProperty(RegistrationData.searchPremiseResults.properties, 'AddressLine2')
            : null,
          Type: 'BILLING',
          PostalCode: getProperty(RegistrationData.searchPremiseResults.properties, 'PostalCode'),
          UDPRN: getProperty(RegistrationData.searchPremiseResults.properties, 'UDPRN'),
          UMPRN: getProperty(RegistrationData.searchPremiseResults.properties, 'UMPRN'),
          SPRN: getProperty(RegistrationData.searchPremiseResults.properties, 'SPRN'),
          UPRN: getProperty(RegistrationData.searchPremiseResults.properties, 'UPRN'),
          Region: getProperty(RegistrationData.searchPremiseResults.properties, 'Region'),
          CountryCode: 'GB',
          SalesStatus: RegistrationData.searchPremiseResults.properties.SalesStatus
            ? RegistrationData.searchPremiseResults.properties.SalesStatus.toUpperCase()
            : 'NO_STATUS',
        },
      },
    ];

    return payload;

  } catch (error) {
    console.log('debug -> Error while creating payload for registration.', error);
  }
};

export const extractLeadIdFromAPIResponse = (response) => {
  return response.find((item) => {
    return item.entity === 'CrmModule:Lead';
  }).id;
};

export const extractContactIdFromAPIResponse = (response) => {
  return response.find((item) => {
    return item.entity === 'CrmModule:Contact';
  }).id;
};

export const extractAddressIdFromAPIResponse = (response) => {
  return response.find((item) => {
    return item.entity === 'CrmModule:Address';
  }).id;
};

export const prepareProductsForOdin = (filteredProducts) => {
  if (filteredProducts) {
    return filteredProducts.map((product) => ({
      quantity: 1,
      entity: 'ProductModule:Product',
      recordId: product.id,
      relatedAssociationId:
        product.dbRecordAssociation && product.dbRecordAssociation.relatedAssociationId
          ? product.dbRecordAssociation.relatedAssociationId
          : null,
    }));
  }
};

export const prepareProductForLeadUpdate = (filteredProduct) => {

  if (filteredProduct) {
    return [{
      quantity: 1,
      entity: 'ProductModule:Product',
      recordId: filteredProduct.id,
      relatedAssociationId: filteredProduct.dbRecordAssociation && filteredProduct.dbRecordAssociation.relatedAssociationId
        ? filteredProduct.dbRecordAssociation.relatedAssociationId
        : null
    }]
  }
}